/*
    @font-face {
        font-family: second;
        src: url("../public/tt.woff2");
    }

    @font-face {
        font-family: intercom;
        src: url("../public/vchoney.woff2");
    }

    body {
        font-family: intercom;
    }
*/


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .highlight {
        @apply bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-transparent
    }
}

.reader {
    h1 {
        @apply hidden
    }
    h2 {
        @apply mt-12 mb-5 text-2xl font-semibold text-gray-900
    }
    h3 {
        @apply mt-10 mb-4 text-xl font-semibold text-gray-900
    }
    h4 {
        @apply mt-8 mb-3 text-base font-semibold text-gray-900
    }
    p {
        @apply text-gray-700 my-5
    }
    figure {
        & {
            @apply relative
        }
        img {
            @apply rounded rounded-xl mt-4
        }
        figcaption {
            @apply absolute bottom-0 px-3 left-0 bg-opacity-70 right-0  h-5 bg-white text-sm text-gray-500 mt-2
        }
    }
    :first-child {
        @apply mt-0
    }
}